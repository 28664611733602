import React from "react"
import Layout from "../../components/layout/layout"
import styled from 'styled-components'
import Title from "../../components/title/title"
import TxtImg from "../../components/txtImg/txtImg"
import { graphql } from "gatsby"
import Panel from "../../images/shutterstock_panel.jpg"
import FullwidthBox from "../../components/fullwidthBox/fullwidthBox"
import { Helmet } from "react-helmet"

const BgWhite = styled.section`
  background-color: var(--neutral-100);
`;

export default function Collection({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Solar Energy Collection | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <Title
        title="Solar Energy Collection"
        subtitle="Photovoltaic Thermal (PVT) panels combine conventional PV electrical generators with thermal energy collectors to produce both electricity and useable heat from one panel."
        gradient={true}
      />
      <BgWhite>
        <TxtImg heading="PV-Thermal" headingColor="accent" imgSrc={data.solarAngelImage.childImageSharp.fluid} alt="PVT panel diagram">
          <p>These combined PV-Thermal generate more energy over a year than the equivalent area of PV, and collect more heat energy than solar thermal panels. They also take up less roof space​​ and are more cost-effective​ than combining conventional PV and Thermal arrays.</p>
        </TxtImg>
        <div className="stdWidth" style={{marginTop: "-3.5rem"}}>
          <p>A Photovoltaic Thermal (PV-T) panel consists of a combined assembly of PV cells bonded to a conductive metal substrate, which carries heat away from the panel into a series of pipes. This heat is then deposited into our thermal energy store (Earth Energy Bank). This keeps the panels cool, enabling them to produce significantly more electricity, typically 15% more annually.</p>
          <p>Another advantage of PV-Thermal is their ability to be mounted within the roofline of a building. Because conventional PV cells can get very hot in the sun, they have to be elevated above the roofline to allow for cooling, making them visually obtrusive.</p>
          <p>In addition to these important benefits,​ the panels can be used to collect energy even when the sun is below the horizon. In “night mode” the heat pump can use them as an energy source by cooling the fluid down below ambient temperature and sending it back to the panels to be warmed by the night air and background infra-red radiation which is present on a cloudy night.</p>
        </div>
      </BgWhite>
      <FullwidthBox image={Panel}>
        The <span>Hybrid PV-T</span> panels become even more efficient when used in combination with our patented energy storage method, the Earth Energy Bank (EEB). All of the excess thermal energy, produced by the panels during the hot summer months, is deposited and utilised during winter.
      </FullwidthBox>
    </Layout>
  )
}

export const query = graphql`
  query {
    solarAngelImage: file(relativePath: {eq: "images/solarangel.png"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`